import React from "react";
import Header from "./Header/Header.js";
import Footer from "./Footer/Footer.js";
import Home from "./Home/Home.js";
import Premium from "./Home/Premium.js";



function Landing() {
  return (
  
    <>
    
            <div>
               <Header/>
               <Home/>
               <Premium/>
               <Footer/>
           
            </div>
          
    </>
  )
}

export default Landing;