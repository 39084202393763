import React from "react";
import { Container, Box, } from '@mui/material';
import {NavLink} from "react-router-dom";
import Grid from '@mui/material/Grid';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";

const  Footer=() => {

  const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))





 const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'instant' });
};


const home=()=>
  {
    
    window.scrollTo({ top: 0, behavior:'instant' });
    window.location.replace('/');
  }

    return (
    
      <footer style={{background:"#f8f8f8",borderTop:"1px solid #dadada"}}>
           
        <Box sx={{bgcolor:"#ffffff", width:"100%", flexGrow:1, pb:'50px',pt:'40px'}}>
        <Container maxWidth="lg">
        <Grid container display="flex" justifyContent="space-between" rowSpacing={2} columnSpacing={{xs:2,sm:2,md:4}} >

            <Grid item xs={6} sm={isSmscreen?6:3} md={3} display="flex" flexDirection='column'  alignItems='start' >

              
            <div><LazyLoadImage className="logo-img" src='/lavendel.svg' alt="Lavendel"  loading="lazy" onClick={home}/></div>
                   
       
               
            </Grid>
            <Grid item xs={6} sm={isSmscreen?6:3} md={3} display="flex" flexDirection='column' alignItems='start'>

                <div className="footers_pagargraph" style={{ display:"flex", flexDirection:'column', alignItems:'start' }}>

                <div className="footer_primary_title" style={{fontWeight:'700',paddingBottom:'8px'}}>About us</div>
                <NavLink to="https://wethink.zohorecruit.com/careers"  target="_blank" className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>Career</NavLink>
                <NavLink to="/about-us" onClick={scrollToTop} className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>About us</NavLink>

                </div>
            </Grid>

            <Grid item xs={6} sm={isSmscreen?6:3} md={3} display="flex" flexDirection='column' alignItems='start'>

                <div className="footers_pagargraph" style={{ display:"flex", flexDirection:'column', alignItems:'start' }}>

                <div className="footer_primary_title" style={{fontWeight:'700',paddingBottom:'8px'}}>Get in Touch</div>
                 <div className= {isSmscreen?"footer_fonts":"footer_font"} style={{fontWeight:600}}>Sales inquiry 
                    </div>
                    <NavLink to="mailto:robin@wethink.co.in" className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>
                    
                        robin@wethink.co.in
                        
                     </NavLink>

                    
                     <div className= {isSmscreen?"footer_fonts":"footer_font"} style={{fontWeight:600,paddingTop:'12px'}}>Product support
                    </div>

                    <NavLink to="mailto:support@wethink.co.in" className= {isSmscreen?"footer_title footer_fonts uxhoverdata":"footer_title footer_font uxhoverdata"}>
                    
                      support@wethink.co.in
                       
                     </NavLink>
                     </div>

            </Grid>

           
            </Grid>
           
            </Container>

          

            </Box>

              <div className="border_top">

            <Container maxWidth="lg">
               <div className="footer_terms">

                <div className="footer_fonts" style={{lineHeight:'20px'}}>
                &copy;{new Date().getFullYear()} Parashar Creative Studio | All rights reserved.
                </div>
                 
                 <div className="footer_condition">
               <span><NavLink to='/terms-and-conditions' onClick={scrollToTop} className= "footer_fonts footer_link uxhover">Terms and Conditions</NavLink></span>
               <span><NavLink to='/privacy-policy' onClick={scrollToTop} className="footer_fonts footer_link uxhover">Privacy Policy</NavLink></span>
               <span><NavLink to='/shipping' onClick={scrollToTop} className= "footer_fonts footer_link uxhover">Shipping Policy</NavLink></span>
               <span><NavLink to='/refund-and-cancellation-policy' onClick={scrollToTop} className="footer_fonts footer_link uxhover">Refund Policy</NavLink></span>
               <span><NavLink to='/return-policy' onClick={scrollToTop} className= "footer_fonts footer_link uxhover">Return Policy</NavLink></span>

      
            </div>
                 
               

               </div>
            </Container>
            </div>


        
          
           
      </footer>

    );
  }
  
  export default Footer;