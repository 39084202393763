import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer'
import Privacy from './Privacy'
import { Container } from "@mui/material";


const Privacypolicylanding=()=>
{
     return(
        <>
            
            <div className='content'>
                <Header/>
                <section style={{backgroundColor:"rgba(233, 225, 255, 1)"}} className="section" >
            <Container maxWidth='lg'>
           <h1 className='hero_title' style={{margin:"0px"}} >Privacy policy</h1>
           </Container>
           </section>
                <Privacy/>
                <Footer/>

            </div>
        </>
     )
}
export default Privacypolicylanding;