import React,{useState} from "react";
import { Box, Container, Grid ,Button} from "@mui/material";
import { createTheme, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import MuiAlert from '@mui/material/Alert';


const Premium=()=>
{
 
  const themes=createTheme({
    breakpoints:{
     values:
     {
        xs:0,
        sm:768
     }
    }
 })

 const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

 
 const customButton=
 {

 backgroundColor:'var(--primary)',
 color:'#ffffff',
fontSize:'18px',
padding:'8px 24px',
borderRadius:'10px',
fontWeight: 500,
textTransform:'none',
margin:isSmscreen?'20px 0px 20px':'30px 0px 30px',
width:'100%', 
 '&:hover': {
backgroundColor:'var(--primary)',
 },
 }

 

 const RedirectUrl= process.env.REACT_APP_SITE_URL;

 const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
 });


const started=()=>
{
  window.open(`${RedirectUrl}/register`, '_blank');
}




    return (
        <>
          
           
           
          <section className="section" id="price">
            <Container maxWidth='lg'>
           
        
          
          <Box sx={{width:'100%'}}>
            <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:4}} display="flex" justifyContent="center" alignItems='center'>

            <Grid item xs={12} sm={isSmscreen?12:8} md={8} >
             
               <div className='superheroes'>

               <h1 className="page_titles" style={{marginBottom:'0px', textAlign:'center'}}> Our Plans and Price </h1>  
               <p className="line_height title text-center" style={{margin:'12px 0px 0px'}}>Choose the best plan for your business. 
               </p>

               </div>
           
             
              </Grid>
              </Grid>
              <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:3,md:3}} display='flex' justifyContent='space-between' style={{paddingTop:'40px'}} >
                   <Grid item xs={12} sm={isSmscreen?12:8} md={4}>
                     <Box className='premium'>
                       <Box className='section_title dynamic'>Basic Plan</Box>
                     
                       <Box className='dynamic_label'>₹300/month</Box>
                         
                       <Button style={customButton} onClick={()=>started('Static Website')}>Get Started</Button>

                         <ul className="item-list home_font" style={{listStyleType:'none',paddingLeft:'0'}}>
                           <li><ChevronRightRoundedIcon/>&nbsp;Website</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;COD</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Limited products listing</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Unlimited Categories</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Unlimited orders management</li>

                         </ul>

                       
                     

                     </Box>
                   </Grid>

                   <Grid item xs={12} sm={isSmscreen?12:8} md={4}>
                     <Box className='premium'>
                     <div className="badge">Most popular</div>
                     <div className="badges"></div>
                       <Box className='section_title dynamic' >
                        Advanced Plan

                       </Box>
                 
                       <Box className='dynamic_label'>₹600/month</Box>
                      
                       <Button style={customButton} onClick={()=>started('Dynamic Website')}>Get Started</Button>

                       <ul className="item-list home_font" style={{paddingBottom:'10px',listStyleType:'none',paddingLeft:'0'}}>
                       <li><ChevronRightRoundedIcon/>&nbsp;Web + android</li>
                        <li><ChevronRightRoundedIcon/>&nbsp;Unlimited products listing</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Unlimited Categories</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Unlimited orders management </li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Payment gateway integration</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Shipping Partners integrated</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Push Notifications</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Order Live Tracking </li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Free SMS notifications</li>
                         

                         </ul>
                         
                      

                     </Box>
                   </Grid>

                   <Grid item xs={12} sm={isSmscreen?12:8} md={4}>
                     <Box className='premium'>
                       <Box className='section_title dynamic'>Premium Plan</Box>
                    
                       <Box className='dynamic_label'> ₹900/month</Box>
                            
                       <Button style={customButton} onClick={()=>started('E-commerce Website')}>Get Started</Button>

                       <ul className="item-list home_font" style={{paddingBottom:'10px',listStyleType:'none',paddingLeft:'0'}}>
                       <li><ChevronRightRoundedIcon/>&nbsp;Web + ios + android</li>
                        <li><ChevronRightRoundedIcon/>&nbsp;Unlimited products listing</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Unlimited Categories</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Unlimited orders management </li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Payment gateway integration</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Shipping Partners integrated</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Push Notifications</li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Order Live Tracking </li>
                            <li><ChevronRightRoundedIcon/>&nbsp;Free SMS notifications</li>
                         </ul>

                         
                         

                     </Box>
                   </Grid>

                </Grid>

               


       
          </Box>
        
          </Container>
          </section>

            


        </>
    )
}
export default Premium;