import React from "react";
import Footer from "../Footer/Footer";
import Cancellation from "./Cancellation.js";
import Header from "../Header/Header.js";
import { Container } from "@mui/material";

const Refundlanding = () => {


   
  


    return (
       <>
          <div className='content'>
                
                <Header/>
                <section style={{backgroundColor:"rgba(233, 225, 255, 1)"}} className="section" >
                    <Container maxWidth='lg'>
                <h1 className='hero_title' style={{margin:"0px"}} >Refund and Cancellation policy</h1>
                </Container>
                </section>
                <Cancellation/>
                <Footer/>

            </div>
       

       </>
    )
}

export default Refundlanding;